import axios from 'axios'

interface ITrackingEvent {
  distinctId: string
  event: string
  host?: string
  path?: string
  urlParams?: string
  target?: string
  pageTitle?: string
  component?: string
  os?: string
  browser?: string
  browserVersion?: string
  source?: string
}

export const trackEvent = async (apiHost: string, body: ITrackingEvent) =>
  axios.post(`https://${apiHost}/events`, body)
