import { Fragment, useEffect } from 'react'
import { observable, action, makeObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { makePersistable } from 'mobx-persist-store'
import { v4 as uuidv4 } from 'uuid'
import { trackEvent } from '../utils/trackEvent'

class Store {
  distinctId: string | null = null

  constructor() {
    makeObservable(this, {
      distinctId: observable,
      setDistinctId: action,
    })

    makePersistable(this, {
      name: 'DistinctIdStore',
      properties: ['distinctId'],
      storage: global.localStorage,
    })
  }

  setDistinctId = (distinctId: string) => (this.distinctId = distinctId)
}

export const distinctIdStore = new Store()

type DistinctIDStoreUpdaterProps = {
  store: Store
  location: URL
}

export const DistinctIDStoreUpdater = observer(
  ({ store, location }: DistinctIDStoreUpdaterProps) => {
    useEffect(() => {
      if (!store.distinctId) {
        const id = uuidv4()
        store.setDistinctId(id)
      }
    }, [store])

    useEffect(() => {
      const isSSR = typeof window === 'undefined'

      if (isSSR || !store.distinctId || !location) {
        return
      }

      const apiHost = process.env.GATSBY_API_HOST

      if (!apiHost) {
        return
      }

      trackEvent(apiHost, {
        distinctId: store.distinctId,
        event: '$pageview',
        host: location.host,
        path: location.pathname,
        urlParams: location.search,
      })
    }, [location, store.distinctId])

    return <Fragment />
  }
)
