exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-employers-tsx": () => import("./../../../src/pages/employers.tsx" /* webpackChunkName: "component---src-pages-employers-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-talk-to-clinician-tsx": () => import("./../../../src/pages/talk-to-clinician.tsx" /* webpackChunkName: "component---src-pages-talk-to-clinician-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-rich-text-page-tsx": () => import("./../../../src/templates/rich-text-page.tsx" /* webpackChunkName: "component---src-templates-rich-text-page-tsx" */)
}

