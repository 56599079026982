import { createContext, useState, PropsWithChildren } from 'react'

type AppContextType = {
  isWhitepaperOpen: boolean
  openWhitepaper: (url: string) => void
  closeWhitepaper: () => void
  whitePaperUrl: string | undefined
}

export const AppContext = createContext<AppContextType>({
  isWhitepaperOpen: false,
  openWhitepaper: (url) => null,
  closeWhitepaper: () => null,
  whitePaperUrl: undefined,
})

export const AppProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isWhitepaperOpen, setIsWhitepaperOpen] = useState(false)
  const [whitePaperUrl, setWhitePaperUrl] = useState<string | undefined>()

  const openWhitepaper = (url: string) => {
    setIsWhitepaperOpen(true)
    setWhitePaperUrl(url)
  }

  const closeWhitepaper = () => {
    setIsWhitepaperOpen(false)
    setWhitePaperUrl(undefined)
  }

  return (
    <AppContext.Provider
      value={{
        isWhitepaperOpen,
        openWhitepaper,
        closeWhitepaper,
        whitePaperUrl,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
